import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  Content,
  ContentType,
  LicenseType,
  PodcastContent,
  RentalStatus,
  VideoContent
} from '../../../interface/home';
import { HibraryRootState } from '../../../redux/rootReducer';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { rentConfirm, rentEbook } from '../../redux/rental/action';
import { RequestStage } from '../../redux/rental';
import { action } from '../../redux/login';
import { useIntl } from 'react-intl';
import { ContentDetail } from '../../../interface/detailPage';
import './index.scss';

interface RentBtnProps {
  content: Content | ContentDetail | VideoContent | PodcastContent;
  contentType: ContentType;
  licenseType: LicenseType;
  disabled?: boolean;
  style?: React.CSSProperties;
  status: RentalStatus;
  size: SizeType;
  className?: string;
  customBtn?: any;
  onClick?: (staus: RentalStatus) => void;
  title?: string;
  licenseUID?: string;
}

export enum ButtonSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small'
}

export const RentBtn = (props: RentBtnProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [btnStatus, setBtnStatus] = useState(props.status);
  const [disableBtn, setDisableBtn] = useState(false);
  const [title, setTitle] = useState<string>();
  const { rental, authen } = useSelector(
    (state: HibraryRootState) => ({
      rental:
        state.rental.id === props.licenseUID ||
        state.rental.id === props.content.contentUID
          ? state.rental
          : undefined,
      authen: state.auth
    }),
    shallowEqual
  );

  useEffect(() => {
    if (props.title) setTitle(props?.title ?? '');
  }, [props.title]);

  const [waitingAmount, setWaitingAmount] = useState(
    props.content.waitingQueue ?? 0
  );

  const RentalStatusClass: {
    [x: string]: { title: string; className: string };
  } = {
    Rent: {
      title: intl.formatMessage({ id: 'Action.BorrowBtn' }),
      className: 'rent'
    },
    Rented: {
      title: intl.formatMessage({ id: 'Action.BorrowedBtn' }),
      className: 'rented'
    },
    WaitingList: {
      title: intl.formatMessage({ id: 'Action.BookBtn' }),
      className: 'waitingList'
    },
    WaitingListed: {
      title: intl.formatMessage({ id: 'Book.Queue' }),
      className: 'waitingListed'
    },
    Returned: {
      title: intl.formatMessage({ id: 'Action.ReturnBtn' }),
      className: 'returned'
    },
    ReturnAble: {
      title: '',
      className: 'returnAble'
    }
  };

  useEffect(() => {
    setBtnStatus(props.status);
  }, [props.status]);

  useEffect(() => {
    if (rental) {
      rental.onConfirm = (content) => {
        content.rentalStatus = rental.rentalStatus ?? RentalStatus.Rent;
        if (rental.rentalStatus === RentalStatus.Rented && props.licenseUID) {
          dispatch(rentEbook(content, props.licenseType, props.licenseUID));
        } else dispatch(rentEbook(content, props.licenseType));
      };
      if ([RequestStage.Error].includes(rental.stage)) {
        setBtnStatus(rental.rentalStatus ?? RentalStatus.Rent);
      }
      if ([RequestStage.Complete].includes(rental.stage)) {
        if (rental.rentalStatus === RentalStatus.WaitingList) {
          setWaitingAmount(waitingAmount + 1);
        }
        if (rental.rentalStatus === RentalStatus.WaitingListed) {
          if (waitingAmount >= 0) setWaitingAmount(waitingAmount - 1);
        }
        const nextStatus = getNextRentalStatus(
          rental.rentalStatus ?? RentalStatus.Rent
        );
        setBtnStatus(nextStatus);
        setTitle(getBtnTitle(nextStatus));
      }
    }
  }, [rental]);

  useEffect(() => {
    if (
      btnStatus === RentalStatus.Rented ||
      rental?.stage === RequestStage.Process
    ) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [btnStatus]);

  const waitingText = () => {
    if (props.title) return props.title;
    else return `${RentalStatusClass[btnStatus].title} (${waitingAmount})`;
  };

  const getBtnTitle = (status: RentalStatus) => {
    if (status === RentalStatus.WaitingListed) {
      return waitingText();
    } else if (status === RentalStatus.WaitingList) {
      return intl.formatMessage({
        id: 'Action.BookBtn'
      });
    } else if (status === RentalStatus.Rented && props.licenseUID) {
      return intl.formatMessage({
        id: 'BorrowingHistory.ReturnBtn'
      });
    } else {
      return RentalStatusClass[btnStatus]
        ? RentalStatusClass[btnStatus].title
        : intl.formatMessage({ id: 'Action.NoDataFound' });
    }
  };

  return (
    <>
      {props.customBtn ? (
        <div
          onClick={() => {
            if (authen.authToken === undefined) {
              dispatch(action.showLogin(true));
            } else {
              dispatch(rentConfirm(props.content, btnStatus));
            }
          }}
        >
          {props.customBtn}
        </div>
      ) : (
        <Button
          loading={rental?.stage === RequestStage.Process ? true : false}
          type="primary"
          shape="round"
          size={props.size}
          onClick={() => {
            if (authen.authToken === undefined) {
              dispatch(action.showLogin(true));
            } else {
              dispatch(rentConfirm(props.content, btnStatus));
            }
          }}
          className={`${
            btnStatus !== undefined
              ? RentalStatusClass[btnStatus].className
              : ''
          } ${props.className}`}
          disabled={props.disabled !== undefined ? props.disabled : disableBtn}
          style={props.style}
        >
          {title ? title : getBtnTitle(btnStatus)}
        </Button>
      )}
    </>
  );
};

export const getNextRentalStatus = (status: RentalStatus) => {
  switch (status) {
    case RentalStatus.Rent:
      return RentalStatus.Rented;
    case RentalStatus.Rented:
      return RentalStatus.Rent;
    case RentalStatus.WaitingList:
      return RentalStatus.WaitingListed;
    case RentalStatus.WaitingListed:
      return RentalStatus.WaitingList;
    default:
      return RentalStatus.Rent;
  }
};
